import { post1 } from './One'
import { post2 } from './Two'
import { post5 } from './Five'
import { post4 } from './Four'
import { post6 } from './Six'
import { post3 } from './Three'
import { post7 } from './Seven'
import { post8 } from './Eight'
import { post9 } from './Nine'

export interface IPost {
    title: string
    content: string
    url: string
    shortDescription: string
    tag: string
    year: number
    imageUrl: string
}

export const posts: IPost[] = [
    post9,
    post8,
    post7,
    post6,
    post1,
    post2,
    post3,
    post4,
    post5,
]
