import { posts } from '../blogPosts/Post'
import { Link } from 'react-router-dom'
import './Home.css'

export const Home = () => {
    return (
        <div className="posts">
            {posts.map((post) => {
                return (
                    <div>
                        <Link
                            style={{
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}
                            to={post.url}
                            key={post.url}
                        >
                            <img
                                width="300px"
                                src={post.imageUrl}
                                alt={post.title}
                            ></img>
                            <h2 className="postTitle">{post.title}</h2>
                            <p className="info">
                                <img src="./assets/time-icon.png" alt=""></img>
                                {post.year} -{' '}
                                <span className="tag">{post.tag}</span>
                            </p>
                            <p className="postDescription">
                                {post.shortDescription}
                            </p>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}
