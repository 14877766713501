import { IPost } from './Post'

export const post3: IPost = {
    title: 'How to protect Azure resources from DDoS?',
    tag: 'azure',
    year: 2021,
    imageUrl: './assets/ddos.png',
    url: '/how-to-protect-azure-resources-from-ddos',
    shortDescription:
        'It will not come as a surprise - every resource has its limits. Servers, virtual machines, networks, etc. are not designed in a way that supports unlimited concurrent users. Let’s imagine that someone really wants to make a given website unavailable. How can they do this? Attackers can use that weakness and send a lot of requests to a given website. At some point, the service cannot handle such traffic and become unavailable - it is called Denial-of-service attack.',
    content: `
It will not come as a surprise - every resource has its limits. Servers, virtual machines, networks, etc. are not designed in a way that supports unlimited concurrent users. Let’s imagine that someone really wants to make a given website unavailable. How can they do this? Attackers can use that weakness and send a lot of requests to a given website. At some point, the service cannot handle such traffic and become unavailable - it is called Denial-of-service attack. 

## What is the difference between DoS and DDoS?
When attackers send requests from one source it is DoS (Denial-of-service attack):
![DoS](./assets/d1.png)

Sending traffic from a lot of various sources to make some website unavailable is called DDoS (Distributed denial-of-service):

![DDoS](./assets/d2.png)

What does the expression 'many requests' really mean? It turned out that in 2012 the DDoS attack that contained 60 Gbps traffic was enough to make several US banking systems unavailable[1]. In 2016 OVH reported attack that reached 1.1 Tbps[2] and two years later GitHub was attacked by 1.3 Tbps[3]. If it is hard for you to imagine that huge amount of data, this level of traffic will be enough to transfer ~80 hours of HD video in one second! To make matters worse Cisco indicates that the frequency of DDoS attacks will increase - from 7.9 million per year in 2018 to 15.4 million in 2023[4]. As you can see, the DDoS attack is a real threat so protecting the resources from such kind of attack is indispensable if we want to reduce the possibility that our system will be unavailable for customers. 

## How can I protect Azure resources from DDoS?

You have two options: you can do nothing, or you can enable the DDoS Protection Standard.

### Do nothing
Why can you do nothing and still have your resources protected in Azure? The answer is simple: all Azure resources have enabled Azure DDoS Protection Basic. The main goal of this tier is to protect Azure’s infrastructure. It provides the ability to monitor the traffic and automatically mitigates the attack when it occurs. However, if you want more advanced functionality, this solution may not meet your requirements. It has also one important limitation: Azure can perceive some traffic as safe and inoffensive, but it can be too heavy for your application to be able to handle it. 

### Use DDoS Protection Standard
Alerts, mitigation reports, DDoS Rapid Response (DRR) team that can help you during the attack and cost guarantee are included only in Standard option. You will be charged about $3,500/month for protecting up to one hundred resources[5]. It is the best option if you want to preserve your application from DDoS since it "will be protected with a profile that is tuned to its expected traffic volume, providing a much tighter defense against DDoS attacks"[6]. 

## How does Azure DDoS Protection work?
It analyses the traffic using machine learning solutions and if some requests seem to be suspicious, they will be dropped. Traffic that is originated by real users will be forwarded to the given website. Thanks to it during the attack the website is still available for customers and at the same time the attackers are blocked to reach the final service.  
![DDoS protection](./assets/d3.png)

## How can I enable Azure DDoS Protection Standard?
To have resources that are protected by Azure DDoS service in Standard plan you must take several steps. This part of the article guides you with all the necessary actions. The goal is to enable Azure DDoS Protection Standard for one resource and configure sending email alert when the attack occurs.  

### 1. Create a resource to protect
Let's assume that we would like to protect a virtual machine. Go to the top bar and search for a virtual machine. You can choose any name, region, image, etc. - it doesn't have an influence on later steps. Creating a virtual machine can automatically generate the virtual network that we will use in the next step. When it is done copy the public IP address of the virtual machine for future purposes. 

![1](./assets/d4.png)

### 2. Create DDoS Protection plan and assign it to your resource
Go to DDoS protection plans -> click New and fill the required fields. When it is created go to Protected resources section for your plan and add a new resource by choosing your virtual network. As you can see in this case, DDoS Protection plan is assigned to the virtual network, but you can also assign DDoS Protection plan to Application Gateway, Load Balancer or Virtual Machine Scale Set. It is good to know that DDoS plan can be even used across multiple subscriptions.

![2](./assets/d5.png)

### 3. Register resource provider
We would like to use Azure Monitor to collect data and use them to sent alerts. To be able to do this we need to add microsoft.insights resource provider to our subscription. Go to Subscription -> Resource Providers and make sure that microsoft.insights is registered. 

![3](./assets/d6.png)

### 4. Create an alert rule
Go to Azure Monitor -> Alerts -> New alert rule -> Select resource and choose your virtual machine public IP address.

![4-1](./assets/d7.png)

In condition section choose as a signal name 'Under DDoS attack or not'. In Alert logic set threshold value as 0. Thanks to it the alert will be activated when the maximum 'under ddos attack or not' value is greater or equal to one. 

![4-2](./assets/d8.png)

In actions section create new action groups. For notification select 'Email/SMS message/Push/Voice' and write your email address.

![4-3](./assets/d9.png)

### 5. Simulate attack on your resources.
There is only one official tool that can be used to simulate DDoS attack on your Azure resources[7]. It is called BreakingPoint Cloud[8] and it offers a trial subscription that contains 5GB of free outbound traffic. After creating your free account, you need to authorize using your Azure subscription - they would like to be sure that you attack only resources that are created by you. Later, you can choose the details of the attack. At this point, the public IP address of your virtual machine will be useful. During configuration of the attack please pay attention to estimated outbound data - for a free trial you have only 5 GB to use. 

![5](./assets/d10.png)

### 6. See the results
After the simulation of the DDoS attack, you can see that DDoS Protection detected suspicious packets.

![6](./assets/d11.png)

When you check your inbox, you should see an email about the DDoS attack:
![6](./assets/d12.png)

### 7. Clean your resources
Don't forget to remove unused resources. To delete the DDoS Protection Standard plan, you should go the virtual network -> DDoS Protection and disable it.
 
![7-1](./assets/d13.png)
After that, you can simply delete your plan and other resources.

During attack simulation, we used the app that needs some permissions in our account. To cancel those permissions, go to MyApplications[9] and click 'revoke permissions'.

![7-2](./assets/d14.png)


## Summary
This near-real live scenario can be used to protect your cloud resources from DDoS attack. Thanks to Standard plan you can be alerted when the attack occurs and have access to metrics collected by Azure Monitor. Also, you can contact DDoS Rapid Response (DRR) team to get some help and you can be sure that you don't be charged for scaling of your services as a result of DDoS attack. As you can see, Azure offers an interesting solution to make your cloud resources safer. 

In this article, you can get to know the basics of DDoS attack and understand why it is a genuine issue. I showed you how to configure Azure DDoS Protection for your resource and how to simulate DDoS attack to your virtual machine. I hope that you enjoyed this post! 

##### References:

[1] https://www.cio.com/article/2389721/ddos-attacks-against-us-banks-peaked-at-60-gbps.html

[2] https://arstechnica.com/information-technology/2016/09/botnet-of-145k-cameras-reportedly-deliver-internets-biggest-ddos-ever/

[3] https://www.wired.com/story/github-ddos-memcached/

[4] https://www.cisco.com/c/en/us/solutions/collateral/executive-perspectives/annual-internet-report/white-paper-c11-741490.html

[5] https://azure.microsoft.com/en-ca/pricing/details/ddos-protection/

[6] https://docs.microsoft.com/en-us/azure/ddos-protection/ddos-faq#are-services-unsafe-in-azure-without-the-service

[7] https://github.com/MicrosoftDocs/azure-docs/issues/59335

[8] https://www.keysight.com/zz/en/products/network-security/breakingpoint-cloud.html

[9] https://myapplications.microsoft.com
`,
}
