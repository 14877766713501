import { IPost } from './Post'

export const post4: IPost = {
    title: '104 revision questions for AZ-104',
    tag: 'azure',
    year: 2020,
    imageUrl: './assets/az-104.png',
    url: '/104-revision-questions-for-az-104',
    shortDescription:
        "If you are preparing for AZ-104 exam, you are likely to find this article highly valuable. Together, we will go over the topics that will help you in preparation to become Microsoft Azure Administrator. When I was learning for the exam, I decided to note the most vital facts about Azure. This post doesn't cover all topics required to AZ-104, but I hope that this list can help you to revise your knowledge before the exam. Let's start!",
    content: `
If you are preparing for AZ-104 exam, you are likely to find this article highly valuable. Together, we will go over the topics that will help you in preparation to become Microsoft Azure Administrator. When I was learning for the exam, I decided to note the most vital facts about Azure. This post doesn't cover all topics required to AZ-104, but I hope that this list can help you to revise your knowledge before the exam. Let's start!

### 1. What is Azure AD?
Azure Active Directory (AD) is known to be a platform to handle identities. It offers single sign-on (SSO), multi-factor authentication, conditional access policies, etc. 

### 2. What are dynamic membership rules?
You can define membership rules for users. If a given user meets the condition, they are added to a group. Thanks to it you can e.g. create groups for users who are from London and are developers. You don't have to add these users manually.
![Dynamic membership rules](./assets/a1.png)

### 3. What is user risk policy?
Azure AD Identity Protection offers functionality to detect which users' behaviours are unusual and suspect. You can define the risk level that you accept and determine what should happen when the expected risk is higher than you agreed. For example, you can specify that if the risk of compromising a user account is medium or high then the user is blocked.
![user risk policy](./assets/a2.png)

### 4. What is sign-in risk policy?
It is similar to the user risk policy. You can define what should happen when the specified risk that the sign-in is coming from someone else than the user is reached. 

### 5. What is conditional access?
As a name suggest, you can specify what should happen for given users when they try to log in. You can, for example, specify that all guests and external users who use Android devices should use multi-factor authentication:
![Conditional access](./assets/a3.png)
It is important that you use conditional access only when you disable security defaults. Security defaults are default rules managed by Microsoft that help to protect from well-known identity-related attacks. 

### 6. What are management groups?
You can group multiple subscriptions together by using management groups. It is important that as opposed to resource groups, management groups can be nested, so you can create a logical hierarchy of your subscriptions. For example, the management group "C" contains the subscription "3" and another management group - "D" that holds subscription "4":
![Management groups](./assets/a4.png)

### 7. Why should I use management groups?
Management groups simplify the administration of your subscriptions. They are usually used together with RBAC or Azure Policies.

### 8. What is RBAC?
RBAC stands for role-based access control. Let's imagine that there are several departments related to your current project: developers, database admins, financial analysts and so on. It will be a good idea to specify which user has what kind of permission. We would like to prevent the situation when financial analysts delete the database or when database admins swap the application's deployment. RBAC offers you an opportunity to define the rules for users who use your Azure subscription. So, you can add necessary rights to developers, other set of permissions to database admins and different privileges to financial analysts.

### 9. What are the scopes for RBAC?
RBAC can be applied to these levels: management group, subscription, resource group, and a given resource.

### 10. What are Azure policies?
Azure policies are used to enforce specified rules and effects for your resources. Let's assume that you want to be sure that all the resources have a tag. Or you want to guarantee that your virtual machines are deployed only in a given region. You can create Azure policies and determine what requirements should be met and what will happen when these conditions are or are not fulfilled.

### 11. What are the scopes for Azure policies?
You can assign a policy to a management group, a subscription, and a resource group. It is good to know that there is also an exclusion option. You can e.g., select that the policy is defined for all resources in your subscription except the resources from a given resource group:
![Azure policies](./assets/a5.png)

### 12. What are resource locks?
Let's imagine that you have a lot of virtual machines - for development, testing and production environment. Sometimes you need to delete virtual machines from the development environment only. To be sure, that you don't delete by mistake any virtual machines from testing or production environment, you can use locks. There are two types of locks: delete (it prevents from deleting a resource) and read-only (it prevents from changing the resources):
![Locks](./assets/a6.png)

### 13. What are the scopes for Azure locks?
You can create a lock for a subscription, resource group, and resource.

### 14. Why read-only locks can be misleading?
Sometimes it is hard to say what does 'modification' mean for Azure resources. There is a lot of side effects of using this type of lock. For example: 
* A read-only lock for a virtual machine prevents the users from starting or restarting the virtual machine.
* A read-only lock on a storage account prevents the users from listing the keys. 

More examples you can find in [the official documentation](https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/lock-resources#considerations-before-applying-locks).

### 15. What will happen when I try to delete a resource with a delete lock?
You will see the information that this operation cannot be completed:
![Azure Locks](./assets/a7.png)

### 16. What is a tag?
A tag is a name and value pair that can be added to resources in Azure. Thanks to tags you can organize your resources. They are commonly used for cost management, resource management, and automation. Let's assume that you work in a company that maintains two applications: Chat Application and Gallery Application. Each app is hosted in a virtual machine. There are two environments: test and prod. When we add tags to these virtual machines, we will know which virtual machine represents what application and environment:
![Tags](./assets/a8.png)

### 17. Can a tag be inherited?
No. For example, when you add a tag to a resource group, the resources within this group won't inherit this tag. 

### 18. What is Azure Budget?
You can create a budget limit for your subscription. Thanks to it, you are able to prevent from spending too much money on Azure. It is important that when you run out of budget, your resources are not stopped. The main goal of Azure Budget is to notify you when the specified limit is achieved.

### 19. What are Azure Action Groups?
During the creation of the budget, you can specify Action Groups. They are a set of effects that should take place when the defined budget limit is met. For example, you can receive an email, phone call or push notification:
![Action Group](./assets/a9.png)
There is also an option to run Azure Function, Logic App or Webhook as an effect of the limit hit. 

### 20. When can I use Azure Action Groups?
It is good to know that Action Groups are not designed only to protect your budget, you can use them in other situations. For example, if your average virtual machine CPU is greater than 70%, then you can notify people in your team about this. That condition can be created by using Alerts in Azure Monitor.

### 21. What is Azure Monitor?
It is a service that provides you with the rich ability to collect and analyze the telemetry of your resources. Azure Monitor contains several subservices and the most important are Application Insights, Log Analytics and Alerts.

### 22. What is Azure Application Insights?
If you want to monitor your applications, Azure Application Insights is one of the best choices. You can collect information about request rates, response times, server, and browser exceptions and many more in a human-friendly way:
![Azure Application Insights](./assets/a10.png)

### 23. What is Log Analytics?
Azure Log Analytics gives you an opportunity to write queries in Kusto query language to analyze your logs. Here are some examples of queries:
* To get all data from 'ExampleTable' table:
${'```'}
ExampleTable
${'```'}
* To count the number of rows contained in a table:
${'```'}
ExampleTable | count
${'```'}
* To search for word 'error' in any property of the data:
${'```'}
ExampleTable | search "error"
${'```'}
It is good to know that Log Analytics can visualise the results of your query and, for example, you will receive a pie chart that you can pin to your dashboard. 

### 24. What is Alerts?
Alerts in Azure Monitor are commonly used to notify you when a certain condition is met. You can use logs and metrics to check if the notification should take place and, for example, send an email to the administrator when your server returns 500 error code.

### 25. What is Infrastructure as Code (IaC)?
Instead of using the Azure portal to create your resources manually, you can define them in a text file - Azure Resource Manager create all your resources based on that file. The file that contains definitions of resources is called ARM template.

### 26. Why should I use ARM template?
ARM template can be stored in a version control system and it is the first advantage of using templates. You can review the modifications and you know who and why have made any change. Additionally, you can save a lot of time, because when you want to deploy the same resources for several environments, you can use one template instead of doing these changes manually. The third reason is about avoiding human errors during the deployments. Since you don't have to make all changes by hand, there is a smaller room for mistakes. 

### 27. What is a shared access signature?
Shared access signature (SAS) is used with a storage account to give access to storage resources. You can specify what actions, services and types are allowed. There is even an option to determine a time slot when the SAS is valid: 
![SAS](./assets/a11.png)

### 28. What is AZCopy?
AZCopy is a tool that provides you with a possibility to copy data to/from your storage account. You can use it on Windows, Linux and macOS.

### 29. Which data I can copy using AZCopy?
You can copy data to/from Blob and File Storage.

### 30. How can I authorize my actions in AZCopy?
You can use SAS token to be able to copy the data. For Blob Storage you have also the second way of authorization: the credentials can be provided from Azure AD.

### 31. How can I use AZCopy from a command line?
AZCopy provides several commands that you can use, but there are two that are the most important: ${'`'}azcopy cp${'`'} and ${'`'}az login${'`'}. 

To log in via Azure AD you should run this command and provide your account login/password:
${'```'}
azcopy login
${'```'}
To upload a single file to your blob storage using SAS authorization you should run this command:
${'```'}
azcopy cp "/yourPath/yourFile.txt" "https://[your-storage-account].blob.core.windows.net/[container]/[path-to-blob]?[SAS-token]"
${'```'}
To download a file from Azure blob storage, you should change the order of arguments:
${'```'}
azcopy cp "https://[your-storage-account].blob.core.windows.net/[container]/[path-to-blob]?[SAS-token]" "/yourPath/yourFile.txt"
${'```'}
A similar command exists for File Storage. When you want to upload a file, you should use:
${'```'}
azcopy cp "/yourPath/yourFile.txt" "https://[your-storage-account].file.core.windows.net/[file-share-name]/[path-to-file]?[SAS-token]"
${'```'}
Here is a command for downloading a file from File Storage:
${'```'}
azcopy cp "https://[your-storage-account].file.core.windows.net/[file-share-name]/[path-to-file]?[SAS-token]" "/yourPath/yourFile.txt"
${'```'}
### 32. What is Azure Import/Export job?
If you have a lot of data that need to be transferred to/from Azure, you can find the idea of importing/exporting them by using physical disk very appealing. Instead of using an Internet connection, Azure can ship to you disk drives. After you upload/download the data to/from disk, you simply send the disk back:
![Import/Export job](./assets/a12.png)

### 33. What are storage types supported for Azure Import/Export job?
For import, you can use both File and Blob storage. For export, only Blob storage is supported. 

### 34. What is WAImportExport tool?
It is used during performing Import/Export job. The tool can help you with preparing the disk drives that are sent for import. You can copy your data to the drive and encrypt the volume using WAImportExport.

### 35. What is scale sets?
If you want to distribute your application across several virtual machines you should use a scale set. It provides the functionality of scaling the number of virtual machines: you can, for example, define that if the total CPU used by your VMs is greater then 85% for 10 minutes, a new virtual machine will be added to the set:
![Scale set](./assets/a13.png)

### 36. Why should I use scale sets?
Scale sets provide you with great flexibility. You can adjust your environment depending on the needs. When few users use your service running on several virtual machines, you can save money by scaling in - the amount of active VMs is decreased. An analogous situation can be for scaling out - when many users request your service, your environment can automatically adjust to that burden. 

### 37. What is a container?
You can run your application with all its dependencies inside a container. It is commonly used to simplify the process of moving application form one environment to another. Instead of installing all the required software in every environment, you can use a container that has installed everything that is needed for the app. 

### 38. What is the difference between a container and a virtual machine?
You can use any operating system inside your virtual machine - it doesn't matter that the host operating system is the same or different. Containers use the operating system of the host, and as a result, they are usually lighter than a virtual machine.

### 39. What is Azure Kubernetes Service?
When you use containers to run your application, Kubernetes can help you with managing them. If you have a lot of containers, Kubernetes orchestrate them, so you don't need to worry about scaling, access management or deployment of a new version of your app to every available container.

### 40. What is Azure Kubernetes node?
A node in Kubernetes is a virtual machine.

### 41. What are Kubernetes pods?
Pods are known to be the smallest deployable units of computing. It simply means that a pod can contain at least one container. 

### 42. What is Kubernetes kubectl?
Kubectl is a command-line client that provides you with the ability to manage your Kubernetes instance. There are a lot of commands, and here I presented only a small subset of them:
To show all your nodes you can use this command.
${'```'}
kubectl get nodes
${'```'}
A similar command is used to display information about your pods:
${'```'}
kubectl get pods
${'```'}
To create resources defined in the YAML file you should use:
${'```'}
kubectl apply -f ./myFile.yaml
${'```'}
When you want to manually scale the resources to 5 you can do it by this command:
${'```'}
kubectl scale --replicas=5 -f ./myFile.yaml
${'```'}

### 43. What is App Service?
Using App Service you are able to host your web application. You can easily deploy your app, specify the deployment details (like deployment slots), and set the scaling rules. It supports several runtime environments for apps written in .NET, Java, Python, Ruby, NodeJS, and PHP:
![App Service](./assets/a14.png)

### 44. What is App Service Plan?
In the App Service Plan, you can specify the virtual machines for your web apps to run. It is important that the App Service Plan can contain several apps - but the operating system and location have to be the same for all your apps. 

### 45. What are Deployment Slots?
Deployment slot is your application with a specified hostname. Since there are several ways of how you can publish a new version of your application, Deployment Slots provide you with flexibility on how your app is deployed. 
* You can, for example, have two Deployment Slots: first for the production version of your app, second that contains new features. You can specify that 80% of the workload will be sent to the old (production) version and 20% to the new version:
![Deployment slots one](./assets/a15.png)
* You can also set that new version of the app won't be available to the users before it is verified by a QA team. After the testing, you can swap the versions:
![Deployment slots two](./assets/a16.png)

### 46. What types of scaling are supported by App Service Plan?
You can scale both up/down and out/in.

### 47. What are the differences between scaling up/down and scaling out/in?
By scaling up you can add more CPU, disk space etc. You scale down when you change the resource type to lower piercing tier: 
![Scale up/down](./assets/a17.png)
By scaling out you can add additional instances of the same resource. Scaling in is meant to decrease the number of running instances:
![Scale out/in](./assets/a18.png)

### 48. What is Azure DNS?
Azure DNS provides you with the ability to host domains. When you open your favourite browser and write for example www.dev.to, the name 'www.dev.to' is translated into an IP address of the server that hosts this website. The main goal of DNS is to convert the domain name to the IP address. 

### 49. What are types of DNS supported in Azure?
You can choose from Public DNS and Private DNS. When you buy a new domain for your app, you can use Public DNS to translate the domain name to the IP address of the web app. Private DNS is used inside your virtual network. For example, you can create database.test and server.test domain names and use it instead of IP addresses. It is important that database.test and server.test are not accessible from the Internet since it was creating using Private DNS. 

### 50. What is a virtual network link?
It is used with Private DNS to connect your domain (like database.test) with a virtual network. Thanks to it, the private domain name will be accessible from the virtual network. 

### 51. What is autoregistration?
When you create a virtual network link, you can enable the autoregistration functionality. When you create a new virtual machine in the virtual network, the DNS record for that VM is automatically created:
![autoregistration](./assets/a19.png)

### 52. What is Azure Files?
If you have a collection of files, you can use Azure Files to store them in the cloud - this is the main goal of Azure Files. 

### 53. What protocols Azure Files uses?
Both SMB (Server Message Block) and NFS (Network File System) are supported.

### 54. How can I use Azure Files?
There are two ways of using Azure Files: you can direct mount of an Azure file share or cache Azure file share with Azure File Sync. 

### 55. How can I create Azure File in my subscription?
Azure Files can be treated as a subservice of Azure Storage Account, so you need to create Azure Storage Account to use Azure Files:
![Azure Files](./assets/a20.png)

### 56. What is Azure File Sync?
Azure File Sync as a name suggests it is meant to synchronise files. Let's assume that you store files in on-premises file server, and you would like to have them copied in the cloud - you can be sure that your files are synchronized between on-premises and cloud by using Azure File Sync:
![Azure File Sync](./assets/a21.png)
It is important that you should install an Azure File Sync agent on the server. 

### 57. What are the differences between server endpoint and cloud endpoint?
Server endpoint is a path on the Windows Server that is synchronized to an Azure file share. Cloud endpoint is simply your Azure file share. 

### 58. What is Azure Sync Group?
Sync group can be interpreted as a logic container. Every endpoint (server and cloud) that are within this sync group are kept in sync. You should create a sync group to use the power of Azure File Sync.

### 59. How many server endpoints can I have in one sync group?
You can have several server endpoints. 

### 60. What should I do to use Azure File Sync?
There are several steps that you should follow to use Azure File Sync:
* Create Azure Files in your subscription.
* Register a server by installing an Azure File Sync agent.
* Create a sync group. 

### 61. What is a Virtual Network?
Azure resources can communicate using the virtual network. When we create e.g., a VM, we can choose which virtual network would be in use. Each virtual network has an IP address range like 10.0.0.0/24. It is an IP address range using CIDR notation.

### 62. What is Network Interface (NIC)?
Thanks to the network interface a virtual machine can have access to the rest of the world. The network interface can have one primary IP address and it is attached directly to a virtual machine.

### 63. Can I have several subnets in a virtual network?
Yes, but important fact is that each of the subnets has to be allocated inside the IP address range. Otherwise, the creation of subnet fails:
![Subnet](./assets/a22.png)

### 64. Can I have no subnet in a virtual network?
No, you have to have at least one subnet in your virtual network. Otherwise, you will not be able to create a virtual network:
![No subnet in VNET](./assets/a23.png)

### 65. How many IP addresses are reserved by Azure in my subnets? 
Azure reserves 5 IP addresses in each subnet: 
![Reserved IP](./assets/a24.png)

### 66. What is the Network Security Group (NSG)?
The main goal of the network security group is to filter the traffic. You can create several roles and specify which traffic is allowed or denied:
![NSG](./assets/a25.png)

### 67. To which Azure resources can I assign NSG?
You can assign NSG to a virtual machine (by using NIC - Network Interface) or to a subnet. 

### 68. Can I assign NSG to more than one resources?
Yes, you can e.g., assign one NSG to several NIC, but it is important that the location of these resources have to be the same. 

### 69. Can I assign several NSG to a virtual machine?
Yes. You can assign first NSG to NIC and second to subnet. Then the virtual machine that is inside the subnet will have two NSG. You can check it in Network Watcher -> Effective security rules: 
![Two NSG to one VM](./assets/a26.png)
It is important that if one of the NSG denies some traffic then the traffic is not allowed - even when the second NSG permit it. 

### 70. How can I check if NSG blocks the traffic?
You can use Network Watcher to check which rule blocks traffic from your virtual machine to other resources. 
![Network Watcher](./assets/a27.png)

### 71. What will happen when my VM doesn't have any NSG?
If your VM is not associated with any NGS then all traffic is allowed.

### 72. What is Azure Firewall?
You can create traffic policies using Azure Firewall. If you want to allow or deny some traffic you can do it by defining NAT rules, network rules, and applications rules. It is good to know that you can even enable the functionality of detecting traffic that goes to or from identified malicious domains or IP addresses.

### 73. What is a route table?
In a route table, you can define how the traffic will be sent by determining a rule: 
![Route table](./assets/a28.png)
For example, we defined that all traffic to 10.3.0.0/16 will go through a virtual appliance (10.4.0.0).

### 74. To which Azure resources can I assign a route table.
You can only assign a route table to a subnet.

### 75. When is it a good idea to use route tables?
A route table is commonly used when we have an Azure Firewall, and we would like to send all traffic from/to a given subnet through the Firewall. As a virtual appliance, you have to specify the public IP of the Firewall. 

### 76. What is Azure Bastion?
During the creation of a virtual machine, you can choose which port will be opened to connecting to the newly created VM. The disadvantage of this solution is the fact, that having a given port open for all the world is not secure. You can see the following warning about it: 
![Warning](./assets/a29.png)
Instead of opening RDP or SSH port of your virtual machine, you can use Azure Bastion to securely connect to the VM. Azure Bastion uses TLS on port 443, so you can connect the VM via web browser:
![Connect to VM using Bastion](./assets/a30.png)
![Connect to VM using Bastion](./assets/a31.png)

### 77. How does Azure Bastion work?
During the creation of Azure Bastion, you have to create a subnet named "AzureBastionSubnet". When you connect to VM by Bastion, the traffic flow is the following:
![Azure Bastion](./assets/a32.png)
You connect to Azure Portal (using HTTPS), Azure Portal connects to Bastion (using HTTPS) and Bastion connect to a given virtual machine (using RDP or SSH).

### 78. What is VNET peering?
When you have two virtual networks, "A" and "B", resources that are inside "A" network cannot communicate to resources from "B" network. The main goal of VNET peering is to enable that communication. To create a peering to a virtual network -> settings -> peerings:
![VNET peering](./assets/a33.png)

### 79. Is a virtual network peering transitive?
Let's assume that we have the following situation:
![Transitive peering](./assets/a34.png)
VnetA has a peering connection to VnetB and VnetB has a peering connection to VnetC. Can resources from VnetA communicate to resources in VnetC? No. There is no default transitive peering. If you want to enable that traffic, you should change the settings of these peerings and allow forwarded traffic from VnetB to VnetA and from VnetB to VnetC.
![Transitive peering](./assets/a35.png)

### 80. Can I create a peering connection to any virtual network I want?
You can create a peering connection between virtual networks in the same region or in different regions, but you cannot connect two virtual networks that have overlapping IP addresses:
![Overlapping virtual networks](./assets/a36.png)

### 81. What is Network Watcher?
Network Watcher helps you to monitor your network. It has several functionalities:
* connection troubleshoot - allows you to **examine** a connection between your VM and another resource. You should use it when you want to diagnose outbound VM communication issues.
* connection monitor - thanks to it you can, for example, **monitor** communication between your VMs.
* VPN diagnostics - allows you to **diagnose** the health of your gateway. If there is a communication issue between your virtual networks, you should use it.
* NSG flow log - allows you to **log** the information about the traffic that goes through your NSG.
* IP flow verify - enable you to **check** if traffic is allowed or denied to/from your virtual machine. We used it to check if NSG blocks the traffic.

### 82. What are Custom Script Extensions?
Let's imagine that you want to create several virtual machines with SQL Server installed on them. You can connect to every machine and install SQL Server manually, but it can take you a lot of time and is error prone. The main goal of Custom Script Extensions is to take such responsibilities from you. You can define a script that installs SQL Server on your virtual machine and determine that the script is run after the deployment. 

### 83. What is a load balancer?
Load balancer as a name suggests is meant to balancing incoming traffic to resources. Let's assume that we have three virtual machines which serve web application, and we want to distribute the traffic between them. Users can initiate a connection to the load balancer and their flow is sent to one of the VM:
![Load balancer](./assets/a37.png)

### 84. What is a health probe?
The load balancer should send traffic only to resources (virtual machines) that are able to receive it. Imagine that one of the virtual machines is unavailable - we don't want to send the user's traffic to it. To prevent such situations the load balancer uses health probes. Every several seconds the load balancer sends TCP/HTTP/HTTPS request to the virtual machines. If a given virtual machine doesn't send any response (for TCP health probes) or returns HTTP response code different than 200 (for HTTP/HTTPS health probes), the probe fails. You can define the number of failed probes when the virtual machine is believed to be unavailable - the load balancer will stop sending user flow to that VM.

### 85. What are the differences between public and internal load balancers?
A public load balancer is used to balance traffic from the Internet to your VMs. An internal load balancer is used for traffic that is inside your virtual network only.
![Load balancers](./assets/a38.png)

### 86. What type of load balancers are available in Azure?
There are two load balancers: Azure Load Balancer and Azure Application Gateway.

### 87. What is Azure Application Gateway?
Azure Application Gateway is a load balancer, but it uses an application layer of the OSI model. It means that Application Gateway can balance traffic based on attributes of HTTP requests:
![Application Gateway](./assets/a39.png)

### 88. What is Azure Load Balancer?
Azure Load Balancer uses a transport layer of the OSI model, so it doesn't have access to the HTTP request details.

### 89. What is a VPN?
VPN stands for a virtual private network. It is used to connect to some resources in a secure way. For example, you can use the VPN to join a virtual network in Azure and thanks to it, you are able to connect to private IPs of your Azure virtual machines. The traffic uses public Internet but is it end-to-end encrypted.

### 90. What is the difference between Point-to-Site (P2S) and Site-to-Site (S2S) VPN connections?
Point-to-Site (P2S) VPN connection is used when you want to connect your virtual network from a client computer. The computer is treated as 'Point', and your virtual network as a 'Site'.
Site-to-Site (S2S) VPN connection lets you connect Azure virtual network to your on-premises network. 

### 91. What is Virtual Network Gateway?
Virtual Network Gateway is commonly used to create VNet-to-VNet connection. When you want to connect to virtual networks, you can create a virtual network gateway in each of these virtual networks and specify the details of the connection.
![VNET Gateway](./assets/a40.png)
It is important that you have to create a subnet named 'GatewaySubnet' that is used by the virtual network gateway. 

### 92. Can I create a VNet-to-Vnet connection to any Azure virtual network I want?
Yes, but the IP address range for these virtual networks should not overlap if you want to connect them.  

### 93. What is VPN Gateway?
It is similar to the virtual network gateway, but it uses encryption before sending the traffic. It is commonly used when you want to connect your on-premises network and Azure virtual network.

### 94. How many VPN Gateway I can have in one virtual network.
You can have only one VPN gateway, but that gateway can have several connections.

### 95. What is an active-active connection?
Let's imagine that we want to create S2S VPN connection. We could do it by creating a VPN Gateway in the virtual network and establish one connection. But what will happen when the connection will be broken? You would not be able to send data between your network and on-premises for several seconds. To avoid such situations, we can use active-active connection. It simply means that a VPN gateway has two Gateway IP configurations with two public IP addresses and connects to one or two (dual-redundancy) IP addresses from on-premises:
![Active-active](./assets/a41.png)
To use active-active connection you should upgrade your SKU to VpnGw1, VpnGw2 or VpnGw3.

### 96. What is ExpressRoute?
It is very similar to a VPN, but instead of using the public Internet to send traffic, the connection is established by an Azure connectivity provider.

### 97. What is WAN?
If you have a highly developed network architecture that contains P2S VPN connections, S2S VPN connections, ExpressRoute and Azure virtual networks and you want to have the ability to connect it all together, you can use WAN:
![WAN](./assets/a42.png)
For example, you can connect from your personal computer using P2S VPN connection to WAN and later to your corporate network by ExpressRoute.

### 98. What is Azure Backup Service?
As a name suggests, Azure Backup Service is responsible for backing up your data. You can back up several resources in Azure like virtual machine and file share:
![Azure Backup](./assets/a43.png)

### 99. What is Recovery Services Vault?
Recovery Service Vault is a container for your backups. When you want to create a backup, you should create this vault first.

### 100. What is the relationship between virtual machines and Recovery Services Vault?
One virtual machine can be backed up by one Backup Service, and the Recovery Services Vault should be in the same region as the virtual machine. One Recovery Services Vault can contain several backups.

### 101. What is Backup Policy?
In Backup Policy you can define when the backup will be taken and how long it will be available. For example, you can define that the daily backup will be stored for 10 days. Backups that were taken every Sunday will be available for 12 weeks and when the backup was created on the first Sunday of the month, it will be saved for 60 months. 
![Backup Policy](./assets/a44.png)

### 102. What is Update Domain?
Sometimes it is needed to conduct planned maintenance for virtual machines. In that situation, the virtual machine can be restarted and, as a result, it is not available for a short time. To avoid that all your virtual machines are restarted at the same time, you can use Update Domain. All virtual machines in a given Update Domain can be restarted together, but it never happens that several Update Domains are restarted at once. 

### 103. What is Fault Domain?
Fault Domain can be treated as a single hardware rack. If there is a malfunction for the rack, then all allocated resources are not available. To prevent situations when all your resources are unusable because of a hardware failure, you should place them in separate Fault Domains. 

### 104. What is Availability Set?
To ensure high availability of your virtual machines you can use Availability Set. You can define how many Fault Domains and Update Domains it contains:
![Availability Set](./assets/a45.png)

`,
}
