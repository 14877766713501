import React, { Component } from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { Layout } from './components/Layout'
import { Home } from './components/Home'
import './custom.css'
import { BlogPost } from './components/BlogPost'
import { NotFound } from './components/NotFound'

export default class App extends Component {
    static displayName = App.name

    render() {
        return (
            <BrowserRouter>
                <Layout>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/not-found" component={NotFound} />
                        <Route path="/:name" component={BlogPost} />
                    </Switch>
                </Layout>
            </BrowserRouter>
        )
    }
}
