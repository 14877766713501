import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams, Link } from 'react-router-dom'
import './BlogPost.css'
import rehypeHighlight from 'rehype-highlight'
import { Helmet } from 'react-helmet'
import { IPost, posts } from '../blogPosts/Post'

export interface IParams {
    name: string
}

export const BlogPost = () => {
    const [blogPost, setBlogPost] = useState({} as IPost)
    const [blogPostFeedback, setBlogPostFeedback] = useState('')
    let params = useParams() as IParams

    useEffect(() => {
        window.scrollTo(0, 0)
        setBlogPost({} as IPost)
        setTimeout(function () {
            let blogPostsContent = posts.filter(
                (p) => p.url === `/${params.name}`
            )
            if (blogPostsContent.length === 0) {
                window.location.href = '/not-found'
            } else {
                setBlogPost(blogPostsContent[0])
                setBlogPostFeedback(
                    'mailto:feedback@blog.kamilbugno.com?subject=' +
                        blogPostsContent[0].title +
                        ' - Feedback'
                )
            }
        }, 100)
    }, [params.name])

    return (
        <>
            {blogPost && blogPost.content && (
                <>
                    <Helmet>
                        <title>{blogPost.title}</title>
                        <meta name="og:title" content={blogPost.title} />
                        <meta name="twitter:title" content={blogPost.title} />
                        <meta
                            name="description"
                            content={blogPost.shortDescription}
                        />
                        <meta
                            name="og:description"
                            content={blogPost.shortDescription}
                        />
                        <meta
                            name="twitter:description"
                            content={blogPost.shortDescription}
                        />
                        <meta
                            name="og:image"
                            content={
                                'https://blog.kamilbugno.com' +
                                blogPost.imageUrl.substring(1)
                            }
                        />
                        <meta
                            name="twitter:image:src"
                            content={
                                'https://blog.kamilbugno.com' +
                                blogPost.imageUrl.substring(1)
                            }
                        />
                    </Helmet>
                    <div className="post">
                        <h1>{blogPost.title}</h1>
                        <p className="info">
                            <img src="./assets/time-icon.png" alt=""></img>
                            {blogPost.year} -{' '}
                            <span className="tag">{blogPost.tag}</span>
                        </p>
                        <img
                            width="300px"
                            src={blogPost.imageUrl}
                            alt={blogPost.title}
                        ></img>
                        <ReactMarkdown
                            children={blogPost.content}
                            rehypePlugins={[rehypeHighlight]}
                        />
                        <p
                            style={{
                                borderTop: 'solid 1px black',
                                paddingTop: '5px',
                            }}
                        >
                            Your opinion is important for me. I’d really
                            appreciate it if you’d be willing to give me
                            feedback related to this post. Just send me an
                            email:{' '}
                            <a href={blogPostFeedback}>
                                feedback@blog.kamilbugno.com
                            </a>
                        </p>
                    </div>
                    <div className="postRecommendation">
                        <div className="postRecommendationContent">
                            <h2>Other posts:</h2>
                            {posts
                                .filter((p) => p.url !== `/${params.name}`)
                                .sort(function (a, b) {
                                    return 0.5 - Math.random()
                                })
                                .slice(0, 3)
                                .map((post) => {
                                    return (
                                        <div className="link">
                                            <Link
                                                style={{
                                                    textDecoration: 'none',
                                                    cursor: 'pointer',
                                                }}
                                                to={post.url}
                                                key={post.url}
                                            >
                                                <h2>{post.title}</h2>
                                                <p>{post.shortDescription}</p>
                                            </Link>
                                        </div>
                                    )
                                })}
                            <h3 style={{ textAlign: 'center' }}>
                                <a href="/" style={{ textDecoration: 'none' }}>
                                    Main page
                                </a>
                            </h3>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
