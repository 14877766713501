import './NavMenu.css'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const NavMenu = () => {
    const location = useLocation()
    const [text, setText] = useState(<></>)
    const onClick = () => {
        window.location.href = '/'
    }

    const bigHeader = (
        <header onClick={onClick}>
            <div className="bigHeader">
                Hi, I am{' '}
                <span style={{ color: 'rgb(3, 102, 102)' }}>Kamil Bugno</span>.
                <p>This is my tech blog.</p>
            </div>
        </header>
    )

    const smallHeader = (
        <header className="smaller" onClick={onClick}>
            <div>
                <span style={{ color: 'rgb(3, 102, 102)' }}>Kamil Bugno</span> -
                blog
            </div>
        </header>
    )

    useEffect(() => {
        if (location.pathname !== '/') {
            setText(smallHeader)
        } else {
            setText(bigHeader)
        }
        window.addEventListener('scroll', resizeHeaderOnScroll)
        // eslint-disable-next-line
    }, [location])

    const resizeHeaderOnScroll = () => {
        const distanceY =
                window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 120

        if (distanceY <= shrinkOn && location.pathname === '/') {
            setText(bigHeader)
        } else {
            setText(smallHeader)
        }
    }

    return <>{text} </>
}
