import { IPost } from './Post'

export const post5: IPost = {
    title: 'Web accessibility',
    tag: 'frontend',
    year: 2020,
    url: '/web-accessibility',
    imageUrl: './assets/accessibility.png',
    shortDescription:
        'If you are an owner of 20/20 vision you probably never spent much time thinking of how lucky you are. But did you know that vision impairment and blindness concern around 285.4 mln people? That’s according to the Centers for Disease Control and Prevention (CDC). Other statistics show that every single year 1 to 2 million people become blind. Regardless of these considerable numbers, vision impairment or blindness has a huge influence on a given person - not only in a physical world but also in a virtual one. How can we, as programmers, help to make the virtual world more accessible?',
    content: `
If you are an owner of 20/20 vision you probably never spent much time thinking of how lucky you are. But did you know that vision impairment and blindness concern around 285.4 mln people? That’s according to the Centers for Disease Control and Prevention (CDC). Other statistics show that every single year 1 to 2 million people become blind[1]. Regardless of these considerable numbers, vision impairment or blindness has a huge influence on a given person - not only in a physical world but also in a virtual one. How can we, as programmers, help to make the virtual world more accessible?

## It is not a new idea
The concept of creating highly accessible websites isn't new. The accessibility of the websites is based on an international standard that has been in force since 1999. That was the year the first version of this standard was published. In 2008 version 2.0 was released, which sets out the standard for developers for creating websites that are accessible to people with disabilities. This standard is called WCAG[2] - Web Content Accessibility Guidelines and specifies requirements for three levels of accessibility: A (basic accessibility), AA (medium accessibility), and AAA (the highest level of accessibility). Even though several years have passed from the first version of that guideline, there are still a lot of websites that are not very friendly towards  people with visual impairments. In Poland, access to websites for people with disabilities is regulated by a legislative act - but this applies only to public institutions. Unfortunately, commercial websites are exempt from this obligation and a lot of these websites are not accessible to people with visual impairments.

## See how people with visual impairments see the world 
Before I started developing a project that had to meet strict accessibility requirements I wasn't aware of that problem. I had never wondered before how the visually impaired people browse the Internet. When I used a screen reader for the first time, I realized how important the accessibility is for people with visual impairments. You can conduct a small experiment and install two extensions for your browser. ChromeVox Classic Extension[3] is a screen reader and every webpage you browse is simply read out loud to you by this extension. Thanks to it blind people can hear the content of the site. All audio descriptions presented in this post come from this extension. The second is NoCoffee Vision Simulator[4] and it allows you to observe how people with visual impairments see. 

The following gif shows how Wikipedia page can look like for a visually impaired person using this extension:
![Gif: example of a visual impairment](./assets/q1.gif)
 
So, for a short period, you can feel like a person with colour blindness, glaucoma, or another vision impairment and experience what your favourite websites look like, given these circumstances. It made a big impression on me.

## Several steps that you can take today to make your website more accessible
Now it is time for us to focus on the technical side of accessibility. I will show you several methods that you can use to improve the accessibility of your website.
### Use semantic HTML
I wanted to understand what the main difficulties of visually impaired people are when it comes to using Internet, so I decided to talk with Andrzej Janik, who represents the Mir foundation[5] and is a blind person. 

>"It is much easier for me to browse the page if it is divided into appropriate sections. The website menu, headers from H1 to H6, article section, and footer are important elements for people with visual impairment".

According to him, we should use semantic HTML wherever possible.

Let's assume that we have this menu item:

![Photo: Example of the menu](./assets/q2.png)

And here is a code for it: 

${'```'}html
<div class="navigation">
 <ul>
   <li><a href="#home">Home</a></li>
   <li><a href="#news">News</a></li>
   <li><a href="#gallery">Gallery</a></li>
   <li><a href="#about">About</a></li>
  </ul>
</div>
${'```'}
You can see that we use '_div_' HTML tag. How does the screen reader present this menu bar to a visually impaired person? When the user focuses on the first element, they can hear:

'_List with four items. Home. Internal link list item._'

This menu bar doesn't use the power of semantic HTML. Why? Because the screen reader doesn't know that this is a menu on our page - '_div_' tag is not very descriptive. We can correct it by using '_nav_' tag instead of '_div_'.

${'```'}html
<nav class="navigation">
 <ul>
   <li><a href="#home">Home</a></li>
   <li><a href="#news">News</a></li>
   <li><a href="#gallery">Gallery</a></li>
   <li><a href="#about">About</a></li>
  </ul>
</nav>
${'```'}
Thanks to it the visually impaired person can hear the following description of the menu:

'_**Navigation** list with four items. Home. Internal link list item._'

The similar situation occurs when it comes to creating buttons. We can create buttons in different ways. Let's assume that we have three following buttons:

${'```'}html
<div class="main-button">First</div>
<input type="button" class="main-button" value="Second">
<button class="main-button">Third</button>
${'```'}

How will the screen reader describe these elements?
* '_First_' - for '_div_' tag
* '_Second. Button_' - for '_input_' tag with _type="button"_
* '_Third. Button_' - for '_button_' tag

As you can notice, using '_div_' tag provides no real value for screen readers. Only two last methods of creating a button can provide an appropriate description for a visually impaired person. So, if you would like to improve the accessibility of your website, you should consider the following tags instead of '_div_'[6]: 
* '_nav_',
* '_button_', 
* '_header_',
* '_footer_', 
* etc.

### Skip to content link
Usually, the majority of websites have many items at the top. A very extensive menu, additional links etc. For example, when you visit the New York Times website, you can see these elements:

![New York Times website](./assets/q3.png)

People with a visual impairment often use a keyboard to navigate the page, not a mouse. As a result, that person must press '_tab_' or '_shift+tab_' to switch between the various content of the site. Can you imagine how many times they have to press '_tab_' to skip all items in the header section? Too many. As a solution for this issue, a lot of websites implement '_Skip to content_' link. 
 
New York Times website also has this link. It is visible only when you are navigating the page using '_tab_'. Thus a visually impaired person can save a lot of time using this link because they do not have to press '_tab_' for every element in the header. How can we implement a link like this?

${'```'}html
<a href="content" class="skip my-style">Skip to content</a>
${'```'}

${'```'}css
.skip {
  position: absolute;
  top: auto;
  left: -1000000000px;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.skip:focus {
  position: static;
  margin: 10px;
  height: auto;
  width: auto;  
}
${'```'}

This skip to content link can also be used in other situations. 
> "One of the Poland news websites usually publishes articles with a video player embedded inside. It is really hard to skip this player because it has a lot of different buttons" said Andrzej Janik. 

It seems that in this situation '_Skip to content_' link can also be helpful. 

### Add description to the photos
Let's assume that we want to add a photo to our website. We can do it with the following code:

${'```'}html
<img class="image" src="./../assets/myPhoto.png" />
${'```'}

How does the screen reader describe this element?
'_My photo image_'

It is important that the screen reader uses filename when there is no alt text or description. On some websites, I can find images with really strange filenames that are not related to the content of the image, for example, pexels-photo_1920x485.jpg. When a visually impaired person comes across the element like this they can feel really confused. How can we improve it?

We can simply add alt text:

${'```'}html
<img class="image" alt="Description of the photo" src="./../assets/myPhoto.png" />
${'```'}

Thanks to it, the screen reader will now read the description of the image.

Another important thing about images is a **meaningful** description. Andrzej Janik told me about a situation where the local authority organized an event and posted an article about it to their website. Unfortunately, all information about that event was included within a poster, so he was not able to access them. In such a situation it can be better to write the details of that event in the description of the image instead of adding an overly generic description like "The poster about our new event". This example shows us one good practice: we should describe the information that is contained within the photo, not the photo itself.

### Connect label with an input element
Let's imagine that we have a huge form, and one of its sections is about choosing one of two elements. We can select either Cat or Dog option.

![Photo: example of the radio buttons](./assets/q4.png)
 
This functionality can be achieved by using the following code:

${'```'}html
<div>                    
  <input id="dog" type="radio" name="animal" /> Dog
  <input id="cat" type="radio" name="animal" /> Cat
</div>
${'```'}
When a user focuses the cursor on the first option, they can hear:

'_Radio button selected._'

It doesn't provide any details about the selected option. The first step to improve the accessibility of it is to add a label element. 

${'```'}html
<div>
 <label>
   <input type="radio" name="animal" /> Dog
 </label>
 <label>
   <input type="radio" name="animal" /> Cat
 </label>
</div>
${'```'}

Then we can hear:

'_**Dog,** radio button selected._'

We can improve this solution even more. How? Let's talk about '*role*' attribute!

### Use 'role' attribute
Do you remember what I recommended about semantic HTML? When it is possible we should use it. But what can we do when we really need to use a simple '_div_'? It turns out that there is a special HTML attribute. It is called '_role_' and thanks to it we can describe what the main goal of a given section of HTML is.

Let's go back to our radio buttons. This solution can be improved  by adding '_role_' attribute.

${'```'}html
<div role="radiogroup">
   <label>
     <input role="radio" type="radio" name="animal" /> Dog
   </label>
   <label>
     <input role="radio" type="radio" name="animal" /> Cat
   </label>
</div>
${'```'}

And instead of hearing: '_Dog, radio button selected_' we now can hear: '_Dog, radio button **one of two** selected_'.

This works for alerts as well. A website can show alert if the user should take immediate action:
![Photo: example of alert](./assets/q5.png)
 
 We can implement such an alert using '_div_' tag:
${'```'}html
<div class="alert">
  <i class="fa fa-close close-button"></i> 
  <strong>Important!</strong> You have to confirm your e-mail.
</div>
${'```'}
When the focus is on that element, the screen reader will describe it as '_Important. You have to confirm your e-mail_'.  As a result, it is not known that this message is an alert. We can add this information by using _role="alert"_ attribute.

${'```'}html
<div class="alert" role="alert">
  <i class="fa fa-close close-button"></i> 
  <strong>Important!</strong> You have to confirm your e-mail.
</div>
${'```'}

Thanks to it, the user will hear '_Important. You have to confirm your e-mail. **Alert**_'. At first glance, this one additional word doesn't seem to be very important, but for a visually impaired person, it is helpful information.

### Use '_aria-..._' attributes
Not only can we use '_role_' attribute in order to make our website more friendly for visual impaired people, but also we have '_aria-..._'[7] attributes at our disposal. It is good to know that aria stands for Accessible Rich Internet Applications and is meant to provide additional information about HTML elements to people with disabilities.

#### aria-label
One of the most popular '_aria-..._' attributes is '_aria-label_' and as a name suggests it provides an additional label to a given element.

Let's assume that we want to use a button without any visible text:
![Photo: example of close button](./assets/q6.png)
 
We can do it by writing:
${'```'}html
<button class="btn">
   <i class="fa fa-close"></i>
</button>
${'```'}
When the screen reader is used to describe that element, we can only hear: '_Button_'. This solution doesn't provide sufficient information for visually impaired people. We can improve it by using '_aria-label_' attribute:

${'```'}html
<button class="btn" aria-label="Close">
   <i class="fa fa-close"></i>
</button>
${'```'}
Now we can hear '_Close button_'.

This attribute is also really helpful when we have a loading icon. If a user has to wait for some data to load, it is a good practice to explain to them the reason why said data isn't shown immediately. Instead of rendering only the loading icon, we should add an '_aria-label_' attribute with an explanation like '_Please wait, the page is loading_'.

#### aria-labelledby

Very similar attribute to '_aria-label_' is a '_aria-labelledby_' attribute. Instead of explicitly writing the content of the label, we can point out that we want to use different HTML element as a label. It can be most helpful in cases when we want to concatenate labels from several other nodes. We can simply add id's of desired elements as arguments to '_aria-labelledby_'.

Let's imagine that we have the following form:
![Photo: example of a form](./assets/q7.png)

We want to add to the fields the information about both the name of the form's section and the label of the input. We can do it by using '_aria-labelledby_' attribute.

${'```'}html
<h1 id="employee">New employee</h1>   
<label id="fName">
  First Name:
  <input type="text" aria-labelledby="employee fName"/> 
</label>
<label id="lName">
  Last Name:
  <input type="text" aria-labelledby="employee lName"/> 
</label>
${'```'}
Screen reader describes the first input as: '_New employee, first name, edit text_'. It can be most useful when the form is really big and contains several sections. Thanks to it, a visually impaired person can be more aware of the context of a given field. 

#### aria-valuemin, aria-valuemax
Another example where we can use '_aria-..._' attribute is a slider.

![Photo: example of a slider](./assets/q8.png)

We can implement it in this way:
${'```'}html
<input type="range" aria-label="Choose the value" min="1" max="100" value="60">
${'```'}

But as a result, screen reader will only mention the current value. So, we can hear '_Choose the value. Sixty, slider_'.

When we change the solution to the one shown below, we will hear: '_Choose the value. Sixty, slider. **Min one, max one hundred**_'.
${'```'}html
<input type="range" aria-label="Choose the value" aria-valuemin="1" aria-valuemax="100" min="1" max="100" value="60">
${'```'}
#### aria-required
In a form, we can have required fields like "Your name".

![Photo: example of the required field](./assets/q9.png)
 
It can be implemented using this HTML:
${'```'}html
 <label>
    Your name: 
   <input type="text" id="name" required/>
 </label>
${'```'}

Screen reader describes it as '_Your name, edit text_'. Unfortunately, the information that this field is required is not presented for a visually impaired user. But when we add '_aria-required_' attribute, then the user knows that they have to fill in this field: 

${'```'}html
 <label>
    Your name: 
   <input type="text" id="name" aria-required="true" required/>
 </label>
${'```'}

As a result, we can hear: '_Your name, edit text, **required**_'.

### How to check if my website is accessible?
In an ideal world, it would be great to use the potential of people with visual impairment for this task. As Andrzej Janik said

> "before any website is made available for users it is verified by a QA team. A similar strategy we can use for testing the accessibility of the page. Why not hire visually impaired people who can verify it from the practical perspective?". 

Although this idea is really interesting, if we don't have that possibility, we can use special tools to test the accessibility of our site. These tools usually don't check everything, so we shouldn't expect that thanks to them our website will be 100% accessible - but they can help us with the most important issues. One such tool is WAVE[8].

![Photo: screen from WAVE extension](./assets/q10.png)

WAVE shows crucial problems related to accessibility, like missing alt texts, skipping headings, missing labels, etc. Similar functionalities are also offered by Chrome Lighthouse[9]. You can audit your site to see what accessibility recommendations are not met:
![Lighthouse extension](./assets/q11.png)


I believe that these tools can provide good support in creating more accessible pages.

### Summary
In this article, I discussed what accessibility is, how it influences the comfort of browsing the internet by visually impaired people, and how we can improve the accessibility of our website. I hope that you will soon have an opportunity to use this knowledge to help in removing virtual barriers for people with disabilities!
 
##### References:
1. Report of the Institute of Healthcare: "Choroby oczu – problem zdrowotny, społeczny oraz wyzwanie cywilizacyjne w obliczu starzenia się populacji"
2. [Official WCAG website](https://www.w3.org/WAI/standards-guidelines/wcag/)
3. [Official website of Chrome Vox extension](http://www.chromevox.com/)
4. [Official website of NoCoffee extenstion](https://accessgarage.wordpress.com/)
5. [Official website of Mir Foundation](https://mir.org.pl/about-foundation/)
6. [More examples of semantic HTML tags from w3schools](https://www.w3schools.com/html/html5_semantic_elements.asp)
7. [More info about ARIA properties from W3](https://www.w3.org/WAI/PF/aria-1.1/states_and_properties)
8. [Official website of WAVE extenstion](https://wave.webaim.org/extension/)
9. [Official website of Lighthouse extenstion](https://chrome.google.com/webstore/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk?hl=en)


`,
}
